import React from 'react';

function NotFound() {
    return (
        <div>
            <h2>404 Not Found</h2>
            <p>The page you're looking for does not exist.</p>
        </div>
    );
}

export default NotFound;
